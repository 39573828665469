import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    towelData:[]
  },
  mutations: {
    towelWay(state,res){
      state.towelData=res.list
    }
  },
  actions: {
  },
  modules: {
  }
})
